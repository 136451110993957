<template>
  <List el="columns-stretch" class="markdown">
      <div v-if="$store.getters.isAdmin">
        <List el="column-between xsm" class="editorTitle">
          <Text el="h3">Markdown Editor</Text>
          <Btn @click="saveMarkdown">save</Btn>
        </List>
        <textarea v-model="markdown" placeholder="# Markdown Editor" class="scrollSync"></textarea>
      </div>
    <div el="list md" class="page scrollSync">
      <div>
        <div v-html="markdownRendered" class="result"></div>
      </div>
      <div style="height:65px;"></div>
    </div>
  </List>
  
</template>
<script>
import { 
  getStorage, 
  ref, 
  uploadString 
} from "firebase/storage";
var md = require('markdown-it')({
  html:true,
  linkify: true,
  typographer: true,
  quotes: '“”‘’',
});

export default {
  name: "Modal",
  props:['url'],
  data() {
    return {
      markdown:'',
      markdownRendered:'',
    };
  },
  methods: {
    async downloadMarkdown(){
      let x = this;
      const response = await fetch(this.url, {
        method: 'GET',
      });
      response.text().then(function (text) {
        // do something with the text response 
        console.log(text);
        x.markdown = text;
      });
    },
    markdownParse(){
      this.markdownRendered = md.render(this.markdown);
      // console.log(this.markdownRendered);
    },
    saveMarkdown() {
      if (
        this.$route.params.path != null &&
        this.$route.params.filename != null
      ) {
        const storage = getStorage();
        const path = this.$route.params.collection +"/" +this.$route.params.path + "/" +this.$route.params.filename
        const storageRef = ref(storage, path);
        uploadString(storageRef, this.markdown).then(() => {
          console.log('Uploaded markdown!');
          alert('Uploaded markdown!');
        });
        
      }else{
        alert('Failed to save: Unable to locate file.');
      }
    },
    scrollSync(selector) {
      let active = null;
      let selected = document.querySelectorAll(selector);
      if(selected.length > 1){
        selected.forEach(function(element) {
          element.addEventListener("mouseenter", function(e) {
            active = e.target;
          });

          element.addEventListener("scroll", function(e) {
            if (e.target !== active) return;

            selected.forEach(function(target) {
              if (active === target) return;

              target.scrollTop = active.scrollTop;
              target.scrollLeft = active.scrollLeft;
            });
          });
        });
      }
      
    }
  },
  watch:{
    markdown: function (){
      this.markdownParse();
    }
  },
  mounted(){
    this.downloadMarkdown();
    this.scrollSync(".scrollSync");
  }
};
</script>
<style lang="scss">
.markdown{
  display: grid;
    grid-auto-columns: 1fr;
    width: 100vw;
    height: calc(100vh - 80px);
    --bg-color:#f1f1f1;
}
textarea{
  padding:32px;
  font-family:inherit;
  font-size:16px;
  border:0;
  border-right:1px solid rgba(0,0,0,0.2);
  resize: none;
  width:100%;
  height:calc(100% - 65px);
  color:#222;
}
.page{
  overflow:auto;
  --bg-color:#fff;
  max-height: calc(100vh - 80px);
}
.result{
  padding:32px;
  height: calc(100vh - 80px);
  max-width: 1000px;
    margin: auto;
}
.editorTitle{
  height: 65px;
  align-items: center;
  align-content: center;
  padding: 0 24px;
  box-shadow: 0 1px 0 rgba(0,0,0,0.2);
  /* border-bottom: 1px solid rgba(0,0,0,0.2);
  border-right: 1px solid rgba(0,0,0,0.2); */
}
.result *{
  box-sizing: border-box;
  font-family: 'Oxygen';
  user-select: none;
}
.markdown{
  img{
    height: 100%;
    max-height: 300px;
    border: 3px solid #f1f1f1;
    margin: auto;
    border-radius: 24px;
    display: inline-block;
  }
  pre {
      background-color: #f7f7f7;
      padding: 13px 15px;
      // border-radius: 8px;
      /* border: 3px solid hsl(0deg 0% 0% / 13%);  */
  }
  p code, li code {
      padding: 2px 4px;
      font-size: 90%;
      color: #257ec7;
      background-color: #f2f5f9;
      border-radius: 4px;
  }
  h1,h2{
    margin-top: 48px;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    padding-bottom: 8px;
  }
  h1,h2,h3,h4,h5,h6 {
      color:var(--color-p2);
      text-transform: uppercase;
      font-weight: bold;
  }
  hr{
    border:0;
    height:1px;
    background-color:hsla(0, 0%, 0%, 0.25);
    margin:32px 0; 
  }
  p, ul, ol{
    color:#222;
    line-height:1.5em;
  }
  strong{
    font-weight:bold;
    color:inherit;
  }
  blockquote {
      padding: 0px 20px;
      margin: 0 0 20px;
      font-size: 17.5px;
      border-left: 5px solid #eee;
  }

  table {
    border-collapse: collapse;
  }
  th{
    font-weight:bold;
  }
  td, th {
    border: 1px solid #d8d8d8;
    padding: 0.5rem;
    text-align: left;
    color:#222;
  }
  tr:nth-child(2n) td {
      background-color: #f4f4f4;
  }
  a{
    color: var(--color-p1);
      text-decoration: none;
      font-size: 1em;
      font-weight:600;
  }
}


</style>
